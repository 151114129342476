// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

// todo: temp c-dark-theme plumbing for select option
.c-dark-theme option {
  background-color: #34343b;
  border: 1px solid #23282c;
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}

.c-main-custom {
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0;
  padding-top: 0.75 * $spacer;

  > .container-fluid {
    @include media-breakpoint-up(md) {
      padding-right: 0.75 * $spacer;
      padding-left: 0.75 * $spacer;
    }
  }
}

label {
  // Allow labels to use `margin` for spacing.
  display: inline-block;
  margin-bottom: 3px !important;
  font-weight: bold;
  font-style: italic;
  font-size: 9pt;
}

.tab-content {
  padding: 7px;
}

.c-default-layout input {
  color: black !important;
}

.c-default-layout textarea {
  color: black !important;
}

.c-default-layout td {
  color: black !important;
}

// .c-dark-theme > .form-control input {
//   background-color: white !important;
// }

.c-dark-theme a {
  color: white !important;
}

.c-dark-theme div{
    background-color: #3c4b64 !important;
    color: white !important;
}

.c-dark-theme input {
  background-color: #3c4b64 !important;
  color: white !important;
  border-color: white !important;
}

.c-dark-theme textarea {
  background-color: #3c4b64 !important;
  color: white !important;
  border-color: white !important;
}

.form-group {
  margin-bottom: 8px !important;
}

.card-body {
  padding: 5px !important;
}

.card-container {
  padding: 10px !important;
}

.table td {
  padding: 0.45rem !important;
}

.test-color {
  background-color: #fcd195 !important;
}